const paintSetReducer = (state, { paints, paintRanges }) => {
  const paintIds = state.paints.map(paint => paint.id)
  const paintRangeIds = state.paintRanges.map(paintRange => paintRange.id)

  return {
    paints: [...state.paints, ...paints.filter(({ id }) => !paintIds.includes(id))],
    paintRanges: [
      ...state.paintRanges,
      ...paintRanges.filter(({ id }) => !paintRangeIds.includes(id))
    ]
  }
}

export default paintSetReducer
