import { useReducer } from "react"
import { uniq } from "lodash"
import { post } from "lib/request"

const UPDATE = "UPDATE"
const UPDATE_QUANTITY = "UPDATE_QUANTITY"
const UPDATE_NEEDED_PAINT = "UPDATE_NEEDED_PAINT"

const quantityReducer = (state, { id, quantity }) => ({
  ...state,
  [id]: quantity
})

const neededReducer = (state, { ids, isNeeded }) => {
  if (isNeeded) {
    return uniq([...state, ...ids])
  } else {
    return state.filter(element => !ids.includes(element))
  }
}

const ownedAndNeededPaintsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        quantities: {
          ...state.quantities,
          ...action.quantities
        },
        neededPaints: uniq([...state.neededPaints, ...action.neededPaints])
      }
    case UPDATE_QUANTITY:
      return {
        ...state,
        quantities: quantityReducer(state.quantities, action)
      }
    case UPDATE_NEEDED_PAINT:
      return {
        ...state,
        neededPaints: neededReducer(state.neededPaints, action)
      }
    default:
      return state
  }
}

export default ({ ownershipsUrl, shoppingListItemsUrl, ...ownedAndNeededPaints }) => {
  const [state, dispatch] = useReducer(ownedAndNeededPaintsReducer, ownedAndNeededPaints)

  const forPaint = ({ id }) => ({
    quantity: state.quantities[id] || 0,
    needed: state.neededPaints.includes(id),
    setQuantity: quantity => {
      const quantityInt = parseInt(quantity, 10) || 0

      post(ownershipsUrl, { paint_id: id, quantity: quantityInt })

      dispatch({ type: UPDATE_QUANTITY, id, quantity: quantityInt })

      if (quantityInt > 0) {
        dispatch({ type: UPDATE_NEEDED_PAINT, ids: [id], isNeeded: false })
      }
    },
    setNeeded: isNeeded => {
      post(shoppingListItemsUrl, { paint_id: id })

      dispatch({ type: UPDATE_NEEDED_PAINT, ids: [id], isNeeded })
    }
  })

  const needAll = ids => {
    post(shoppingListItemsUrl, { paint_ids: ids })

    dispatch({ type: UPDATE_NEEDED_PAINT, ids, isNeeded: true })
  }

  const update = inventory => {
    dispatch({ type: UPDATE, ...inventory })
  }

  return {
    forPaint,
    needAll,
    update,
    ...state
  }
}
