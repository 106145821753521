import * as React from "react"
import classNames from "classnames"

const Heading1 = ({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"h1">): JSX.Element => {
  const classes = classNames("text-black text-4xl xs:text-5xl", className)

  return (
    <h1 {...props} className={classes}>
      {children}
    </h1>
  )
}

export default Heading1
