import React from "react"

import PaintSwatch from "../paint_swatch"
import PaintOwnershipBadge from "../paint_ownership_badge"

import "./styles.scss"

const PaintSummary = ({
  manufacturerCode,
  name,
  type,
  swatchStyle,
  paintRange,
  isOwned
}) => {
  const fullName = type !== "Base" ? `${name} (${type})` : name

  return (
    <div className="paint-summary">
      <div className="paint-summary__swatch">
        <PaintSwatch swatchStyle={swatchStyle} />
      </div>
      <div className="paint-summary__label">
        <div className="paint-summary__paint-range">{paintRange.name}</div>
        <div className="paint-summary__paint">
          <PaintSwatch manufacturerCode={manufacturerCode} name={fullName} />
          <PaintOwnershipBadge isOwned={isOwned} />
        </div>
      </div>
    </div>
  )
}

export default PaintSummary
