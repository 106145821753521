import * as React from "react"
import { nanoid } from "nanoid"
import { useState } from "react"
import { useMutation, gql, Reference } from "@apollo/client"
import { isEqual, uniq } from "lodash"

import CollectionSummary from "./collection_summary"
import CollectionForm from "./collection_form"
import EditableCollection from "./editable_collection"
import { Placeholder, Heading2, PrimaryButton } from "components/brush"
import { Collection, CollectionFormValues, LogEntry } from "../types"

import {
  DEFAULT_TOTALS,
  collectTotals,
  collectTotalsGroupedByCollection
} from "./../lib/collect_totals"

import CreateCollection from "./../mutations/create_collection.graphql"

type CollectionListProps = {
  viewerId: string
  collections: Collection[]
  logEntries: LogEntry[]
}

const CollectionList = ({ viewerId, collections, logEntries }: CollectionListProps) => {
  const [isAdding, setIsAdding] = useState(false)

  const totals = collectTotals(logEntries)
  const collectionTotals = collectTotalsGroupedByCollection(logEntries)
  const collectionIds = uniq(logEntries.map(({ collection }) => collection?.id || 0))

  const handleAdd = () => setIsAdding(true)
  const handleCancel = () => setIsAdding(false)

  const [createCollection] = useMutation(CreateCollection, {
    update(
      cache,
      {
        data: {
          createCollection: { collection }
        }
      }
    ) {
      cache.modify({
        id: viewerId,
        fields: {
          collections: existingCollectionConnection => {
            const newCollectionRef = cache.writeFragment({
              data: collection,
              fragment: gql`
                fragment NewCollection on Collection {
                  id
                  name
                }
              `
            })

            return {
              ...existingCollectionConnection,
              nodes: [...existingCollectionConnection.nodes, newCollectionRef]
            }
          }
        }
      })
    }
  })

  const handleSubmit = (input: CollectionFormValues) => {
    createCollection({
      variables: { input },
      optimisticResponse: {
        createCollection: {
          __typename: "CreateCollectionPayload",
          collection: {
            __typename: "Collection",
            id: nanoid(),
            ...input
          }
        }
      }
    })

    setIsAdding(false)
  }

  return (
    <>
      <header className="flex items-center border-solid border-0 border-t-2 border-neutral-300 pt-4 px-4 xs:px-0 -mx-4 xs:mx-0 mb-5">
        <Heading2 id="collections-heading" className="m-0 mr-4">
          Collections
        </Heading2>

        <PrimaryButton size="small" onClick={handleAdd} disabled={isAdding}>
          Add collection
        </PrimaryButton>
      </header>

      {isAdding ? (
        <CollectionForm onSubmit={handleSubmit} onCancel={handleCancel} />
      ) : null}

      {collections.length > 0 || logEntries.length > 0 ? (
        <ul aria-labelledby="collections-heading" className="m-0 p-0 list-none">
          {isEqual(collectionIds, [0]) || collectionIds.length > 1 ? (
            <li>
              <CollectionSummary name="All log entries" {...totals} />
            </li>
          ) : null}

          {collections.map((collection: Collection) => (
            <li key={collection.id}>
              <EditableCollection
                {...(collectionTotals[collection.id] || DEFAULT_TOTALS)}
                {...collection}
              />
            </li>
          ))}

          {collectionIds.length > 1 && collectionIds.includes(0) ? (
            <li>
              <CollectionSummary
                name="No collection"
                {...(collectionTotals[0] || DEFAULT_TOTALS)}
              />
            </li>
          ) : null}
        </ul>
      ) : !isAdding ? (
        <Placeholder>
          <p className="text-lg m-0">You don't have any collections&nbsp;yet.</p>
        </Placeholder>
      ) : null}
    </>
  )
}

export default CollectionList
