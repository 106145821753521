import React, { useRef, useState } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { isEmpty, isEqual, countBy } from "lodash"

import filterPaints from "../../lib/filter_paints"
import groupPaintsByType from "../../lib/group_paints_by_type"
import PaintBlock from "../paint_block"
import UnauthenticatedPaintBlock from "../paint_block/unauthenticated"
import PaintRangeCount from "../paint_range_count"
import useOwnedAndNeededPaints from "hooks/use_owned_and_needed_paints"
import { FilterBar, SelectFilter, TextFilter } from "../filter_bar"

import "./styles.scss"

const DEFAULT_FILTERS = {
  name: "",
  needed: "",
  ownership: "",
  range: "",
  type: ""
}

const OWNED = "owned"
const NOT_OWNED = "not-owned"

const NEEDED = "needed"
const NOT_NEEDED = "not-needed"

const applyFilters = ({ paints, quantities, neededPaints, filters }) => {
  const { name, range, type, ownership, needed } = filters

  if (!isEmpty(range)) {
    paints = paints.filter(({ paintRangeId }) => paintRangeId === range)
  }

  if (!isEmpty(type)) {
    paints = paints.filter(paint => paint.type === type)
  }

  if (!isEmpty(ownership)) {
    paints = paints.filter(({ id }) => {
      const inInventory = quantities[id] > 0

      return ownership === OWNED ? inInventory : !inInventory
    })
  }

  if (!isEmpty(needed)) {
    paints = paints.filter(({ id }) => {
      const onShoppingList = neededPaints.includes(id)

      return needed === NEEDED ? onShoppingList : !onShoppingList
    })
  }

  return filterPaints({ paints, token: name })
}

const countOwnedPaintsByPaintRange = (paints, quantities) =>
  countBy(
    Object.entries(quantities)
      .filter(([_, quantity]) => quantity > 0)
      .map(([paintId]) => paints.find(({ id }) => paintId == id)),
    "paintRangeId"
  )

const paintOwnershipsChanged = ownerships =>
  new CustomEvent("paint-ownerships-changed", {
    detail: {
      ownerships
    }
  })

const PaintCollection = ({
  paints,
  paintRanges,
  isAuthenticated,
  showPaintRangeHeaders,
  showOwnershipFilter,
  ownershipsUrl,
  shoppingListItemsUrl,
  quantities = {},
  neededPaints = []
}) => {
  const isInitialMount = useRef(true)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const ownedAndNeededPaints = useOwnedAndNeededPaints({
    quantities,
    neededPaints,
    ownershipsUrl,
    shoppingListItemsUrl
  })

  const filterableTypes = Array.from(new Set(paints.map(({ type }) => type))).sort()
  const filterablePaintRanges = Object.values(paintRanges)

  const filteredPaints = groupPaintsByType(
    paintRanges,
    applyFilters({
      paints,
      filters,
      ...ownedAndNeededPaints
    })
  )

  const isFiltered = !isEqual(filters, DEFAULT_FILTERS)
  const hasPaints =
    filteredPaints.reduce(
      (totalPaints, paintRange) => totalPaints + paintRange.selectedPaints,
      0
    ) > 0
  const hasManufacturerCodes = paints.find(
    ({ manufacturerCode }) => !isEmpty(manufacturerCode)
  )

  const ownedPaintsByRange = countOwnedPaintsByPaintRange(
    paints,
    ownedAndNeededPaints.quantities
  )

  const updateFilter = filterName => event => {
    setFilters({ ...filters, [filterName]: event.target.value })
  }

  const clearFilters = event => {
    event.preventDefault()

    setFilters(DEFAULT_FILTERS)
  }

  useDeepCompareEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      window.dispatchEvent(paintOwnershipsChanged(ownedPaintsByRange))
    }
  }, [ownedPaintsByRange])

  return (
    <>
      <FilterBar
        isFiltered={isFiltered}
        onClear={clearFilters}
        toggleLabel="Filter paints"
      >
        {filterablePaintRanges.length > 1 && (
          <SelectFilter
            name="paint-range-filter"
            label="Filter by range"
            value={filters.range}
            onChange={updateFilter("range")}
          >
            {filterablePaintRanges.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </SelectFilter>
        )}

        {filterableTypes.length > 1 && (
          <SelectFilter
            name="paint-type-filter"
            label="Filter by type"
            value={filters.type}
            onChange={updateFilter("type")}
          >
            {filterableTypes.map(paintType => (
              <option value={paintType} key={paintType}>
                {paintType}
              </option>
            ))}
          </SelectFilter>
        )}

        {showOwnershipFilter && (
          <SelectFilter
            name="paint-ownership-filter"
            label="Filter by ownership"
            value={filters.ownership}
            onChange={updateFilter("ownership")}
          >
            <option value={OWNED}>In your inventory</option>
            <option value={NOT_OWNED}>Not in your inventory</option>
          </SelectFilter>
        )}

        <SelectFilter
          name="paint-needed-filter"
          label="Filter by shopping list"
          value={filters.needed}
          onChange={updateFilter("needed")}
        >
          <option value={NEEDED}>On your list</option>
          <option value={NOT_NEEDED}>Not on your list</option>
        </SelectFilter>

        <TextFilter
          name="paint-name-filter"
          label={
            hasManufacturerCodes ? "Filter by name/manufacturer code" : "Filter by name"
          }
          value={filters.name}
          onChange={updateFilter("name")}
        />
      </FilterBar>

      <div className="safe-area">
        <div className="full-page">
          {hasPaints ? (
            filteredPaints.map(({ id, name, url, paintTypes, paintsCount }) => (
              <div className="paint-collection__paint-range" key={id}>
                {showPaintRangeHeaders && (
                  <header className="paint-collection__paint-range__header">
                    <h2 className="paint-collection__paint-range__heading">
                      <a href={url}>{name}</a>
                    </h2>

                    <PaintRangeCount
                      owned={ownedPaintsByRange[id]}
                      total={paintsCount}
                      isAuthenticated={isAuthenticated}
                    />
                  </header>
                )}

                <div className="paint-collection__paint-range__paints">
                  {paintTypes.map(({ name, paints }) => (
                    <div className="paint-collection__paint-type" key={name}>
                      <h2 className="paint-collection__paint-type__heading">{name}</h2>

                      <ul className="paint-collection__paints">
                        {paints.map(paint => (
                          <li className="paint-collection__paint" key={paint.id}>
                            {isAuthenticated ? (
                              <PaintBlock
                                {...ownedAndNeededPaints.forPaint(paint)}
                                {...paint}
                              />
                            ) : (
                              <UnauthenticatedPaintBlock {...paint} />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="empty-message">
              <p>There aren&rsquo;t any paints that match your current filters.</p>
              <p>
                <a href="#" onClick={clearFilters}>
                  Clear filters
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default PaintCollection
