import React from "react"

import CheckIcon from "../../../views/icons/check.svg"

import "./styles.scss"

const PaintOwnershipBadge = ({ isOwned }) => {
  if (isOwned) {
    return (
      <span className="paint-ownership-badge">
        <CheckIcon />
        Owned
      </span>
    )
  } else {
    return null
  }
}

export default PaintOwnershipBadge
