import * as React from "react"
import { compact } from "lodash"
import { CollectionWithTotals } from "../types"

import CountList from "./count_list"

type CollectionSummaryProps = Omit<CollectionWithTotals, "id"> & {
  children?: React.ReactNode
}

const CollectionSummary = ({
  name,
  totalEntries,
  totalModels,
  totalPoints,
  children
}: CollectionSummaryProps) => {
  return (
    <div className="p-4 -mx-2 xs:mx-0 mb-4 border-black border border-solid">
      <div className="flex ">
        <h4 className="m-0 text-xl sm:text-2xl leading-6 text-neutral-900 flex-1 mr-4">
          {name}
        </h4>
        {children && <div>{children}</div>}
      </div>

      <CountList
        items={[
          { count: totalEntries, singular: "entry", plural: "entries" },
          { count: totalModels, singular: "model" },
          { count: totalPoints, singular: "point" }
        ]}
      />
    </div>
  )
}

export default CollectionSummary
