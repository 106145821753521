import * as React from "react"
import classNames from "classnames"

interface PrimaryButtonProps<T extends React.ElementType> {
  as?: T
  size?: "small" | "large"
  children?: React.ReactNode
}

const DEFAULT_CLASSES = `
leading-4
font-display
font-bold
cursor-pointer
rounded
border-2
border-solid
border-orange-200
bg-orange-200
text-black
hover:bg-orange-300
hover:border-orange-300
focus:outline
focus:outline-offset-1
focus:outline-2
focus:outline-orange-500
disabled:bg-neutral-300
disabled:text-neutral-600
disabled:border-neutral-300
disabled:cursor-not-allowed
`

const SIZE_CLASSES = {
  small: "px-4 py-1.5 text-base",
  large: "px-8 py-3 text-xl uppercase"
}

function PrimaryButton<T extends React.ElementType = "button">({
  as,
  size = "large",
  className,
  children,
  ...props
}: PrimaryButtonProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof PrimaryButtonProps<T>>) {
  const Component = as || "button"
  const classes = classNames(DEFAULT_CLASSES, SIZE_CLASSES[size], className)

  return (
    <Component {...props} className={classes}>
      {children}
    </Component>
  )
}

export default PrimaryButton
