import * as React from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

import MoreIcon from "../../../../views/icons/more.svg"

type ContainerProps = {
  children: React.ReactNode
}

const Container = ({ children }: ContainerProps): JSX.Element => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        aria-label="Menu"
        className="bg-white p-0 border-solid border-2 rounded border-orange-300 cursor-pointer hover:border-orange-400 focus:outline focus:outline-offset-1 focus:outline-2 focus:outline-orange-400"
      >
        <MoreIcon className="fill-neutral-800 w-5 h-5 block" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className="bg-white shadow-dropdown rounded py-2 mt-1"
        align="start"
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

const Item = ({
  children,
  ...props
}: DropdownMenu.DropdownMenuItemProps): JSX.Element => {
  return (
    <DropdownMenu.Item
      {...props}
      asChild
      className="text-base px-6 py-1 outline-none block border-0 w-full text-left bg-transparent hover:bg-orange-300 focus:bg-orange-200 text-neutral-800 cursor-pointer"
    >
      <button>{children}</button>
    </DropdownMenu.Item>
  )
}

export default { Container, Item }
