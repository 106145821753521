import React from "react"

const IconLink = ({ label, onClick, Icon }) => (
  <button className="link-button icon-link icon-link--no-underline" onClick={onClick}>
    <span className="icon-link__icon">
      <Icon />
    </span>
    <span className="icon-link__label">{label}</span>
  </button>
)

export default IconLink
