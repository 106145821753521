import { Controller } from "stimulus"

export default class extends Controller<HTMLDivElement> {
  static targets = ["link"]

  declare readonly hasLinkTarget: boolean
  declare readonly linkTarget: HTMLAnchorElement
  declare readonly linkTargets: HTMLAnchorElement[]

  connect() {
    this.element.style.cursor = "pointer"
  }

  handleClick(event: MouseEvent) {
    // Don't do anything if the click will be handled by a parent element
    if (event.target && (event.target as HTMLElement).closest("a, button")) {
      return
    }

    // From: https://www.growingwiththeweb.com/2016/07/redirecting-dom-events.html
    const forwardEvent = new MouseEvent(event.type, event)
    this.linkTarget.dispatchEvent(forwardEvent)
  }
}
