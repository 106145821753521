import React from "react"

import showAuthenticationModal from "../lib/show_authentication_modal"

const handleSignUpClick = event => {
  event.preventDefault()

  showAuthenticationModal("paint_range")
}

const PaintRangeCount = ({ total, owned = 0, isAuthenticated = false }) => (
  <p className="paint-range-count">
    {owned > 0 ? (
      <>
        <span className="paint-range-count__owned">{owned}</span>
        <span className="paint-range-count__slash">/</span>
        {total} paints owned
      </>
    ) : (
      `${total} paints`
    )}

    {!isAuthenticated ? (
      <>
        {" "}
        <span className="paint-range-count__promo">
          <a onClick={handleSignUpClick} href="#">
            Sign up to add paints to your inventory
          </a>
        </span>
      </>
    ) : null}
  </p>
)

export default PaintRangeCount
