import * as React from "react"
import { nanoid } from "nanoid"
import { useMutation, gql } from "@apollo/client"

import LogEntrySummary from "./log_entry_summary"
import LogEntryForm from "./log_entry_form"
import { LogEntry, LogEntryFormValues } from "../types"
import { Placeholder } from "components/brush"

import prepareLogEntryInput from "../lib/prepare_log_entry_input"

import CreateLogEntry from "../mutations/create_log_entry.graphql"

type LogEntryListProps = {
  viewerId: string
  logEntries: LogEntry[]
  isAdding: boolean
  handleCancel: () => void
}

const LogEntryList = ({
  viewerId,
  logEntries,
  isAdding,
  handleCancel
}: LogEntryListProps) => {
  const [createLogEntry] = useMutation(CreateLogEntry, {
    update(
      cache,
      {
        data: {
          createLogEntry: { logEntry }
        }
      }
    ) {
      cache.modify({
        id: viewerId,
        fields: {
          logEntries: existingLogEntryConnection => {
            const newLogEntryRef = cache.writeFragment({
              data: logEntry,
              fragment: gql`
                fragment NewLogEntry on LogEntry {
                  id
                  description
                  notes
                  models
                  points
                  completedOn
                  collection {
                    id
                    name
                  }
                }
              `
            })

            return {
              ...existingLogEntryConnection,
              nodes: [...existingLogEntryConnection.nodes, newLogEntryRef]
            }
          }
        }
      })
    }
  })

  const handleSubmit = (input: LogEntryFormValues) => {
    createLogEntry({
      variables: { input: prepareLogEntryInput(input) },
      optimisticResponse: {
        createLogEntry: {
          __typename: "CreateLogEntryPayload",
          logEntry: {
            __typename: "LogEntry",
            id: nanoid(),
            ...input
          }
        }
      }
    }).catch(error => console.log(error))

    handleCancel()
  }

  return (
    <>
      {isAdding ? <LogEntryForm onSubmit={handleSubmit} onCancel={handleCancel} /> : null}

      {logEntries.length > 0 ? (
        <ul aria-label="Log entries" className="m-0 p-0 list-none">
          {logEntries.map((logEntry: LogEntry) => (
            <li key={logEntry.id} className="mb-9">
              <LogEntrySummary {...logEntry} />
            </li>
          ))}
        </ul>
      ) : !isAdding ? (
        <Placeholder>
          <p className="text-lg m-0 md:py-20">
            You haven't added anything to your hobby&nbsp;log&nbsp;yet.
          </p>
        </Placeholder>
      ) : null}
    </>
  )
}

export default LogEntryList
