import * as React from "react"
import { useState } from "react"
import { format, parseISO } from "date-fns"

import CountList from "./count_list"
import LogEntryForm from "./log_entry_form"
import { LogEntry, LogEntryFormValues } from "../types"
import { useMutation } from "@apollo/client"

import UpdateLogEntry from "../mutations/update_log_entry.graphql"
import DeleteLogEntry from "../mutations/delete_log_entry.graphql"
import prepareLogEntryInput from "../lib/prepare_log_entry_input"

import { DropdownMenu } from "components/brush"

const LogEntrySummary = ({
  id,
  description,
  collection,
  completedOn,
  notes,
  points,
  models
}: LogEntry) => {
  const [isEditing, setIsEditing] = useState(false)

  const [updateCollection] = useMutation(UpdateLogEntry)

  const [deleteLogEntry, { loading: isDeleting }] = useMutation(DeleteLogEntry, {
    variables: { input: { id } },
    update(cache) {
      cache.evict({ id: `LogEntry:${id}` })
    },
    optimisticResponse: {
      deleteLogEntry: {
        logEntry: {
          __typename: "LogEntry",
          id
        }
      }
    }
  })

  const onSubmit = (values: LogEntryFormValues) => {
    updateCollection({
      variables: { input: { id, ...prepareLogEntryInput(values) } },
      optimisticResponse: {
        updateLogEntry: {
          __typename: "UpdateLogEntryPayload",
          logEntry: {
            __typename: "LogEntry",
            id,
            ...values
          }
        }
      }
    })

    setIsEditing(false)
  }

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this log entry?")) {
      deleteLogEntry()
    }
  }

  return (
    <>
      {isEditing ? (
        <LogEntryForm
          values={{ description, collection, completedOn, notes, points, models }}
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div className="-mx-4 xs:m-0">
          <header className="flex items-start border-solid border-0 border-t-2 border-neutral-300 pt-4 px-4 xs:px-0">
            <div className="mr-3 flex-1">
              <p className="m-0 font-semibold text-base text-neutral-500">
                {format(parseISO(completedOn), "dd MMMM y")}
              </p>

              <h4 className="m-0 leading-6 font-bold text-2xl md:text-3xl text-neutral-900 ">
                {description}
              </h4>
            </div>

            <DropdownMenu.Container>
              <DropdownMenu.Item onClick={() => setIsEditing(true)}>
                Edit log entry
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={handleDelete} disabled={isDeleting}>
                {isDeleting ? "Removing..." : "Delete log entry"}
              </DropdownMenu.Item>
            </DropdownMenu.Container>
          </header>

          <div className="px-4 xs:px-0">
            {collection ? (
              <h5 className="m-0 mt-1 font-semibold text-lg text-neutral-600">
                {collection.name}
              </h5>
            ) : null}

            {notes ? (
              <p className="m-0 mt-2 whitespace-pre-line text-neutral-700">{notes}</p>
            ) : null}

            <CountList
              items={[
                { count: models, singular: "model" },
                { count: points, singular: "point" }
              ]}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default LogEntrySummary
