import * as React from "react"
import classNames from "classnames"

const Heading2 = ({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"h2">): JSX.Element => {
  const classes = classNames("text-black text-2xl md:text-3xl", className)

  return (
    <h2 {...props} className={classes}>
      {children}
    </h2>
  )
}

export default Heading2
