import React from "react"
import * as HoverCard from "@radix-ui/react-hover-card"

const SupporterHoverCard = ({ target, username, supportUrl, isSupporter }) => {
  const content = { __html: target }

  return (
    <HoverCard.Root openDelay={400}>
      <HoverCard.Trigger dangerouslySetInnerHTML={content} />
      <HoverCard.Content className="supporter-hover-card" align="start">
        <p className="supporter-hover-card__text">
          <strong>{username}</strong> is a Patreon supporter, investing in Paint Pad to
          help it stay independent, ad-free, and constantly improving with new features
          and&nbsp;enhancements.
        </p>
        {!isSupporter ? (
          <p className="supporter-hover-card__text">
            <a
              href={supportUrl}
              target="_blank"
              rel="noreferrer"
              className="button button--flat button--secondary supporter-hover-card__cta"
            >
              Become a supporter
            </a>
          </p>
        ) : null}
      </HoverCard.Content>
    </HoverCard.Root>
  )
}

export default SupporterHoverCard
