import * as React from "react"
import classNames from "classnames"

const Placeholder = ({
  className,
  children,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  const classes = classNames(
    "border-2 border-dotted border-neutral-400 text-center py-10 px-6 mb-9",
    className
  )

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  )
}

export default Placeholder
