import * as React from "react"
import { useField } from "formik"

import { useFieldContainer } from "./field"
import { Input } from "components/brush"

const NumberField = (props: React.ComponentPropsWithoutRef<"input">) => {
  const { id, name, errorId } = useFieldContainer()
  const [{ value, ...field }, meta] = useField(name)

  return (
    <Input
      type="number"
      id={id}
      aria-describedby={meta.error ? errorId : undefined}
      value={value === null || value === undefined ? "" : value}
      className="w-32"
      {...field}
      {...props}
    />
  )
}

export default NumberField
