import React, { useState } from "react"

import { patch, del } from "lib/request"
import PaintSwatch from "../../paint_swatch"

import CheckIcon from "../../../../views/icons/check.svg"

const Item = ({ paint, paintRange, url, handleRemove, ...props }) => {
  const [isPurchased, setIsPurchased] = useState(props.isPurchased)

  const onCheckboxClick = () => {
    setIsPurchased(!isPurchased)

    patch(url)
  }

  const onRemoveClick = event => {
    event.preventDefault()
    handleRemove()

    del(url)
  }

  return (
    <li
      className={`shopping-list-item ${
        isPurchased ? "shopping-list-item--purchased" : ""
      }`}
    >
      <div className="shopping-list-item__checkbox-container">
        <button className="shopping-list-item__checkbox" onClick={onCheckboxClick}>
          {isPurchased && <CheckIcon />}
        </button>
      </div>

      <div className="shopping-list-item__wrapper">
        <div className="shopping-list-item__content">
          <div className="shopping-list-item__paint">
            <PaintSwatch {...paint} />{" "}
            <span className="shopping-list-item__type">{paint.type}</span>
          </div>

          <div className="shopping-list-item__paint-range">
            <a href={paintRange.url}>{paintRange.name}</a>
          </div>
        </div>

        <div className="shopping-list-item__remove">
          <a href="#" onClick={onRemoveClick}>
            Remove
          </a>
        </div>
      </div>
    </li>
  )
}

export default Item
