import React, { useState } from "react"

import PaintSwatch from "../../paint_swatch"
import pluralize from "../../../lib/pluralize"

const PaintsWithoutAlternativesList = ({ paints }) => {
  const [showList, setShowList] = useState(false)

  const toggleList = () => {
    setShowList(previousShowList => !previousShowList)
  }

  return (
    <div className="alternative-paint-selector__missing-alternatives">
      <h6>
        {pluralize(
          paints.length,
          "This recipe uses a paint that does not have a known alternative.",
          "This recipe uses % paints that do not have any known alternatives."
        )}{" "}
        <button className="link-button" onClick={toggleList}>
          {showList ? "Hide details" : "Show details"}
        </button>
      </h6>

      {showList && (
        <>
          <p>
            {paints
              .map(paint => <PaintSwatch key={paint.id} {...paint} inline />)
              .reduce((prev, curr) => [prev, ", ", curr])}
          </p>
          <p>
            You can help improve the number of paints that have alternatives by{" "}
            <a href="mailto:support@paintpad.app">suggesting an additional data source</a>
            .
          </p>
        </>
      )}
    </div>
  )
}

export default PaintsWithoutAlternativesList
