import * as React from "react"
import { nanoid } from "nanoid"
import { ReactNode, useMemo } from "react"
import { useField } from "formik"
import classNames from "classnames"

type FieldContextProps = {
  id: string
  name: string
  errorId: string
}

function createTypedContext<A extends {}>(name: string) {
  const ctx = React.createContext<A | undefined>(undefined)

  function useCtx() {
    const c = React.useContext(ctx)
    if (c === undefined) throw `use${name} must be inside a ${name}Provider with a value`
    return c
  }

  return [useCtx, ctx.Provider] as const
}

export const [useFieldContainer, FieldContainerProvider] =
  createTypedContext<FieldContextProps>("FieldContainer")

export const Container = ({
  name,
  children,
  className,
  ...props
}: {
  name: string
  className?: string
  children: ReactNode
}) => {
  const id: string = useMemo(() => `${name}_${nanoid()}`, [name])
  const errorId = `${id}_error`

  const classes = classNames("mb-3", className)

  return (
    <FieldContainerProvider value={{ name, id, errorId }}>
      <div {...props} className={classes}>
        {children}
      </div>
    </FieldContainerProvider>
  )
}

export const Label = ({ children, ...props }: { children: ReactNode }) => {
  const { id } = useFieldContainer()

  return (
    <div>
      <label
        htmlFor={id}
        {...props}
        className="text-lg text-neutral-900 inline-block font-semibold"
      >
        {children}
      </label>
    </div>
  )
}

export const Error = (props: object) => {
  const { name, errorId } = useFieldContainer()
  const [_field, meta, _helpers] = useField(name)

  if (meta.touched && meta.error) {
    return (
      <p
        role="alert"
        id={errorId}
        className="text-red-700 mb-0 mt-1 text-base font-semibold"
        {...props}
      >
        {meta.error}
      </p>
    )
  } else {
    return null
  }
}
