const replacePaintsWithAlternatives = ({
  paintSet: { paints },
  originalPaints,
  alternativePaints
}) =>
  originalPaints.map(paint => {
    const alternativePaintId = alternativePaints[paint.id]

    if (alternativePaintId) {
      return paints.find(({ id }) => id === alternativePaintId)
    } else {
      return paint
    }
  })

export default replacePaintsWithAlternatives
