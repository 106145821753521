import { groupBy, mapValues } from "lodash"

import { CollectedTotals, CollectionTotals, LogEntries } from "../types"

export const DEFAULT_TOTALS: CollectionTotals = {
  totalEntries: 0,
  totalModels: 0,
  totalPoints: 0
}

export const collectTotals = (logEntries: LogEntries) =>
  logEntries.reduce(
    ({ totalEntries, totalModels, totalPoints }, { models, points }) => ({
      totalEntries: totalEntries + 1,
      totalModels: totalModels + (models || 0),
      totalPoints: totalPoints + (points || 0)
    }),
    DEFAULT_TOTALS
  )

export const collectTotalsGroupedByCollection = (
  logEntries: LogEntries
): CollectedTotals => {
  return mapValues(
    groupBy(logEntries, logEntry => logEntry.collection?.id || 0),
    collectTotals
  )
}
