import React from "react"
import { components } from "react-select"

import PaintSwatch from "../../paint_swatch"
import PaintOwnershipBadge from "../../paint_ownership_badge"

const PaintSingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <PaintSwatch {...data} />
    <PaintOwnershipBadge isOwned={data.isOwned} />
  </components.SingleValue>
)

export default PaintSingleValue
