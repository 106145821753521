export enum AnalyticsEvent {
  CLOSE_AUTHENTICATION_MODAL = "authentication_modal:close",
  OPEN_AUTHENTICATION_MODAL = "authentication_modal:open",
  SWITCH_TO_SIGN_UP_WITHIN_AUTHENTICATION_MODAL = "authentication_modal:switch_to_sign_up",
  SWITCH_TO_SIGN_IN_WITHIN_AUTHENTICATION_MODAL = "authentication_modal:switch_to_sign_in"
}

interface PosthogClient {
  capture(event: string, properties: {}): void
}

declare const posthog: PosthogClient

export const track = (event: AnalyticsEvent, properties = {}) => {
  posthog.capture(event, properties)
}
