import { LogEntryFormValues, LogEntryInput } from "../types"

const prepareLogEntryInput = ({
  collection,
  ...input
}: LogEntryFormValues): LogEntryInput => ({
  ...input,
  collectionId: collection ? collection.id : null
})

export default prepareLogEntryInput
