import { Controller } from "stimulus"
import crel from "crel"
import { sample } from "lodash"

const randomLower = () => Math.abs(Math.random() - Math.random())
const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
const randomLowerInt = (min, max) => Math.floor(randomLower() * (1 + max - min) + min)
const randomUpperInt = (min, max) => max - randomLowerInt(min, max)

const hexToRbg = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

const rgbaHex = (hex, a) => {
  const { r, g, b } = hexToRbg(hex)

  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export default class extends Controller {
  connect() {
    this.canvas = crel("canvas")
    this.ctx = this.canvas.getContext("2d")

    this.element.appendChild(this.canvas)

    this.ctx.globalCompositeOperation = "overlay"

    this.currentWidth = this.element.offsetWidth
    this.currentHeight = this.element.offsetHeight

    this.resizeCanvas()
    this.draw()
  }

  get devicePixelRatio() {
    return window.devicePixelRatio || 1
  }

  get backingStoreRatio() {
    return (
      this.ctx.webkitBackingStorePixelRatio ||
      this.ctx.mozBackingStorePixelRatio ||
      this.ctx.msBackingStorePixelRatio ||
      this.ctx.oBackingStorePixelRatio ||
      this.ctx.backingStorePixelRatio ||
      1
    )
  }

  get ratio() {
    return this.devicePixelRatio / this.backingStoreRatio
  }

  get colors() {
    this._colors = this._colors || JSON.parse(this.data.get("colors"))
    return this._colors
  }

  redraw() {
    // Only redraw if the size of the canvas will change
    if (
      this.currentWidth == this.element.offsetWidth &&
      this.currentHeight == this.element.offsetHeight
    ) {
      return
    }

    this.currentWidth = this.element.offsetWidth
    this.currentHeight = this.element.offsetHeight

    this.clearCanvas()
    this.resizeCanvas()
    this.draw()
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }

  resizeCanvas() {
    const width = this.element.offsetWidth
    const height = this.element.offsetHeight

    this.canvas.width = width * this.ratio
    this.canvas.height = height * this.ratio

    this.canvas.style.width = `${width}px`
    this.canvas.style.height = `${height}px`

    this.ctx.scale(this.ratio, this.ratio)
  }

  draw() {
    const maxCircleSize = 18
    const width = this.canvas.offsetWidth
    const height = this.canvas.offsetHeight
    const total = Math.ceil(
      (width * height) / (maxCircleSize * maxCircleSize) / (maxCircleSize / 3)
    )

    for (var i = 0; i < total; i++) {
      const x = randomInt(0 - maxCircleSize, width + maxCircleSize)
      const y = maxCircleSize + randomUpperInt(0, height - maxCircleSize)
      const r = randomLowerInt(maxCircleSize / 2, maxCircleSize)

      let alpha = (y / height - randomLower()).toFixed(2)
      if (alpha < 0) alpha = 0.15

      this.ctx.fillStyle = rgbaHex(sample(this.colors), alpha)
      this.ctx.beginPath()
      this.ctx.arc(x, y, r, 0, 2 * Math.PI, true)
      this.ctx.closePath()
      this.ctx.fill()
    }
  }
}
