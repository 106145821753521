import * as React from "react"
import classNames from "classnames"

const DEFAULT_CLASSES = `
text-neutral-900
text-xl
border-neutral-300
border-solid
border-2
px-3
py-1
rounded
hover:border-neutral-400
focus:border-orange-500
focus:outline
focus:outline-4
focus:outline-orange-100
`

const Input = ({ className, ...props }: React.ComponentPropsWithoutRef<"input">) => {
  const classes = classNames(DEFAULT_CLASSES, className)

  return <input className={classes} {...props} />
}

export default Input
