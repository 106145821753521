import * as React from "react"

import { UserAction } from "../types"

const UserActionHiddenFields = ({ name, params }: UserAction) => (
  <>
    <input type="hidden" name="perform_user_action" value={name} />
    {Object.entries(params).map(([name, value]) => (
      <input
        key={name}
        type="hidden"
        name={`user_action_params[${name}]`}
        value={value}
      />
    ))}
  </>
)

export default UserActionHiddenFields
