import * as React from "react"

type CountListItem = {
  count: number | null
  singular: string
  plural?: string
}

type ContainerProps = {
  items: CountListItem[]
}

const Item = ({ count, singular, plural }: CountListItem): JSX.Element | null => {
  const label = count === 1 ? singular : plural ? plural : `${singular}s`

  return (
    <li className=" bg-neutral-200 rounded-full text-neutral-700 py-0.5 px-4 text-xs sm:text-base mr-3">
      <strong>{count ? count.toLocaleString() : 0}</strong> {label}
    </li>
  )
}

const CountList = ({ items }: ContainerProps): JSX.Element | null => {
  const visibleItems = items.filter(({ count }) => count)

  if (visibleItems.length === 0) {
    return null
  }

  return (
    <ul className="list-none p-0 flex mt-3">
      {visibleItems.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </ul>
  )
}

export default CountList
