import * as React from "react"
import { useState } from "react"
import { useMutation } from "@apollo/client"

import CollectionForm from "./collection_form"
import CollectionSummary from "./collection_summary"
import { CollectionFormValues, CollectionWithTotals } from "../types"
import { DropdownMenu } from "components/brush"

import UpdateCollection from "../mutations/update_collection.graphql"
import DeleteCollection from "../mutations/delete_collection.graphql"

const EditableCollection = ({ id, name, ...totals }: CollectionWithTotals) => {
  const [isEditing, setIsEditing] = useState(false)

  const [updateCollection] = useMutation(UpdateCollection)

  const [deleteCollection, { loading: isDeleting }] = useMutation(DeleteCollection, {
    variables: { input: { id } },
    update(cache) {
      cache.evict({ id: `Collection:${id}` })
    },
    optimisticResponse: {
      deleteCollection: {
        collection: {
          __typename: "Collection",
          id
        }
      }
    }
  })

  const onSubmit = (values: CollectionFormValues) => {
    updateCollection({
      variables: { input: { id, ...values } },
      optimisticResponse: {
        updateCollection: {
          __typename: "UpdateCollectionPayload",
          collection: {
            __typename: "Collection",
            id,
            ...values
          }
        }
      }
    })

    setIsEditing(false)
  }

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this collection?")) {
      deleteCollection()
    }
  }

  return (
    <>
      {isEditing ? (
        <CollectionForm
          values={{ name }}
          onSubmit={onSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <CollectionSummary name={name} {...totals}>
          <DropdownMenu.Container>
            <DropdownMenu.Item onClick={() => setIsEditing(true)}>
              Edit collection
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={handleDelete} disabled={isDeleting}>
              {isDeleting ? "Removing..." : "Delete collection"}
            </DropdownMenu.Item>
          </DropdownMenu.Container>
        </CollectionSummary>
      )}
    </>
  )
}

export default EditableCollection
