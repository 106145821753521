import { Controller } from "stimulus"

const hash = originalHash => originalHash.split("#")[1]

export default class extends Controller {
  static targets = ["tab", "heading", "content"]

  get currentClass() {
    return "recipe-page__tab--current"
  }

  get currentTab() {
    return this.element.querySelector(`.${this.currentClass}`)
  }

  get currentContent() {
    return document.getElementById(this.currentHash)
  }

  connect() {
    this.currentHash = hash(location.hash)
      ? hash(location.hash)
      : hash(this.tabTargets[0].href)

    this.updateCurrentTab()

    this.contentTargets.forEach(content => {
      content.style.display = content.id === this.currentHash ? "block" : "none"
    })

    this.headingTargets.forEach(heading => (heading.style.display = "none"))
  }

  switch(event) {
    event.preventDefault()

    history.replaceState({}, "", event.target.href)

    this.currentTab.classList.remove(this.currentClass)
    this.currentContent.style.display = "none"

    this.currentHash = hash(event.target.href)
    this.updateCurrentTab()
    this.currentContent.style.display = "block"
  }

  updateCurrentTab() {
    this.tabTargets
      .find(tab => hash(tab.href) === this.currentHash)
      .classList.add(this.currentClass)
  }
}
