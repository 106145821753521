import { post } from "../../../lib/request"

export const fetchAlternativePaints = paintIds =>
  post("/api/alternative_paints", { paint_ids: paintIds }).then(response =>
    response.json()
  )

export const persistAlternativePaints = ({ recipeId, alternatives }) =>
  post("/api/recipe_alternative_paints", {
    recipe_id: recipeId,
    alternatives
  })
