import * as React from "react"
import * as Yup from "yup"
import { Form, Formik } from "formik"

import * as Field from "./form/field"
import TextField from "./form/text_field"
import { CollectionFormValues } from "../types"
import { Heading2, PrimaryButton, SecondaryButton } from "components/brush"

interface CollectionFormProps {
  values?: CollectionFormValues
  onCancel: () => void
  onSubmit: (values: CollectionFormValues) => void
}

const defaultValues: CollectionFormValues = {
  name: ""
}

const CollectionSchema = Yup.object({
  name: Yup.string().required("Enter a name for this collection")
})

const CollectionForm = ({
  values,
  onCancel,
  onSubmit
}: CollectionFormProps): JSX.Element => {
  const initialValues: CollectionFormValues = values
    ? { ...defaultValues, ...values }
    : defaultValues

  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault()

    onCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={CollectionSchema}
    >
      {formik => (
        <Form className="p-4 mb-4 -mx-2 xs:mx-0 border-black border-2 border-solid shadow-square">
          <Heading2 className="m-0 mb-3">{values ? "Edit" : "Add"} collection</Heading2>

          <Field.Container name="name">
            <Field.Label>Collection name</Field.Label>
            <TextField autoFocus />
            <Field.Error />
          </Field.Container>

          <div className="flex mt-8 items-center">
            <div className="mr-4">
              <PrimaryButton
                size="small"
                type="submit"
                className="uppercase"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Saving..." : "Save"}
              </PrimaryButton>
            </div>
            <div>
              <SecondaryButton size="small" className="uppercase" onClick={handleCancel}>
                Cancel
              </SecondaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CollectionForm
