import { Controller } from "stimulus"
import Mousetrap from "mousetrap"

export default class extends Controller {
  connect() {
    this.isOpen = false
    this.toggleClassName = "dropdown--active"

    this.close = this.close.bind(this)
    this.handleClick = this.handleClick.bind(this)

    this.mousetrap = new Mousetrap()
    this.mousetrap.bind("escape", this.close)

    document.addEventListener("mousedown", this.handleClick, false)
    document.addEventListener("touchstart", this.handleClick, false)
  }

  disconnect() {
    this.mousetrap.reset()

    document.removeEventListener("mousedown", this.handleClick, false)
    document.addEventListener("touchstart", this.handleClick, false)

    this.element.classList.remove(this.toggleClassName)
  }

  open() {
    this.isOpen = true
    this.element.classList.add(this.toggleClassName)
  }

  close() {
    this.isOpen = false
    this.element.classList.remove(this.toggleClassName)
  }

  toggle() {
    if (!this.isOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  handleClick(event) {
    if (this.element.contains(event.target)) {
      return
    }

    this.close()
  }
}
