import React, { useState, useReducer } from "react"
import { QueryClientProvider } from "react-query"
import ReactDOM from "react-dom"
import classnames from "classnames"
import { intersection } from "lodash"

import AlternativePaintSelector from "../alternative_paint_selector"
import IconLink from "../icon_link"
import PaintList from "../paint_list"
import RecipeContent from "../recipe_content"
import SwapIcon from "../../../views/icons/swap.svg"

import pluralize from "../../lib/pluralize"
import groupAndTokeniseRecipeItems from "../../lib/recipe_items"
import useOwnedAndNeededPaints from "hooks/use_owned_and_needed_paints"
import paintSetReducer from "./reducers/paint_set_reducer"
import replacePaintsWithAlternatives from "./lib/replace_paints_with_alternatives"
import queryClient from "./lib/query_client"

const PaintListPortal = ({ container, children }) =>
  ReactDOM.createPortal(children, container)

const RecipePage = ({
  recipeId,
  alternativePaints,
  hasAlternativePaintsAvailable,
  recipeContent,
  paintListContainer,
  paintCollection,
  includeUserTools,
  isAuthenticated
}) => {
  const ownedAndNeededPaints = useOwnedAndNeededPaints(paintCollection)
  const [isChoosingPaints, setIsChoosingPaints] = useState(false)
  const [selectedAlternativePaints, setSelectedAlternativePaints] = useState(
    alternativePaints.selected
  )
  const [paintSet, updatePaintSet] = useReducer(paintSetReducer, {
    paints: [...paintCollection.paints, ...alternativePaints.paints],
    paintRanges: [...paintCollection.paintRanges, ...alternativePaints.paintRanges]
  })

  const items = groupAndTokeniseRecipeItems(
    recipeContent.items,
    paintSet.paints,
    selectedAlternativePaints
  )

  const paintsForPaintList = replacePaintsWithAlternatives({
    paintSet,
    originalPaints: paintCollection.paints,
    alternativePaints: selectedAlternativePaints
  })

  const alternativePaintsCount = intersection(
    Object.keys(selectedAlternativePaints),
    paintCollection.paints.map(({ id }) => id)
  ).length

  const choosePaints = () => setIsChoosingPaints(true)
  const stopChoosingPaints = () => setIsChoosingPaints(false)

  const selectAlternativePaints = ({
    selectedAlternativePaints,
    ownedAndNeededAlternativePaints,
    ...paintSet
  }) => {
    stopChoosingPaints()
    updatePaintSet(paintSet)
    setSelectedAlternativePaints(selectedAlternativePaints)
    ownedAndNeededPaints.update(ownedAndNeededAlternativePaints)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RecipeContent {...recipeContent} items={items} />
      {paintListContainer && (
        <PaintListPortal container={paintListContainer}>
          {isChoosingPaints && (
            <AlternativePaintSelector
              recipeId={recipeId}
              paints={paintCollection.paints}
              paintRanges={paintCollection.paintRanges}
              selectedAlternativePaints={selectedAlternativePaints}
              paintQuantities={ownedAndNeededPaints.quantities}
              onSubmit={selectAlternativePaints}
              onCancel={stopChoosingPaints}
              saveAlternativePaints={isAuthenticated}
            />
          )}

          <div
            className={classnames("recipe-page__paint-list", {
              "recipe-page__paint-list--is-choosing-alternative-paints": isChoosingPaints
            })}
          >
            {hasAlternativePaintsAvailable && (
              <div className="recipe-page__paint-list__header">
                {alternativePaintsCount > 0 ? (
                  <>
                    <h6 className="recipe-page__paint-list__heading">
                      {pluralize(
                        alternativePaintsCount,
                        "This recipe is using one alternative paint",
                        "This recipe is using % alternative paints"
                      )}
                    </h6>
                    <IconLink
                      onClick={choosePaints}
                      label="Change alternative paints"
                      Icon={SwapIcon}
                    />
                  </>
                ) : (
                  <IconLink
                    onClick={choosePaints}
                    label="Choose alternative paints for this recipe"
                    Icon={SwapIcon}
                  />
                )}
              </div>
            )}

            <PaintList
              includeUserTools={includeUserTools}
              isAuthenticated={isAuthenticated}
              paints={paintsForPaintList}
              paintRanges={paintSet.paintRanges}
              ownedAndNeededPaints={ownedAndNeededPaints}
            />
          </div>
        </PaintListPortal>
      )}
    </QueryClientProvider>
  )
}

export default RecipePage
