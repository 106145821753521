import * as React from "react"
import { useField } from "formik"

import { useFieldContainer } from "./field"

const CLASSES = `
w-full
text-neutral-900
text-xl
border-neutral-300
border-solid
border-2
px-3
py-1
rounded
hover:border-neutral-400
focus:border-orange-500
focus:outline
focus:outline-4
focus:outline-orange-100
`

const TextArea = (props: React.ComponentPropsWithoutRef<"textarea">) => {
  const { id, name, errorId } = useFieldContainer()
  const [{ value, ...field }, meta] = useField(name)

  return (
    <textarea
      id={id}
      aria-describedby={meta.error ? errorId : undefined}
      value={value || ""}
      className={CLASSES}
      {...field}
      {...props}
    />
  )
}

export default TextArea
