import * as React from "react"
import * as Dialog from "@radix-ui/react-dialog"

interface HeaderContentProps {
  mode: string | undefined
  isSignUp: boolean
}

const headerContent = (
  mode: string | undefined,
  isSignUp: boolean
): { heading: string; description: string | null } => {
  const upOrIn = isSignUp ? "up" : "in"

  switch (mode) {
    case "inventory":
      return {
        heading: "Track your paints",
        description: `Sign ${upOrIn} to Paint Pad to see the paints you own at a glance, on mobile or desktop.`
      }

    case "shopping_list":
      return {
        heading: "Track paints you need",
        description: `Sign ${upOrIn} to Paint Pad to add paints to your shopping list. Review the list when you’re in your favourite hobby store.`
      }

    case "paint_range":
      return {
        heading: "Track your paints",
        description: `Sign ${upOrIn} to Paint Pad to see the paints you have and the ones you need at a glance, on mobile or desktop.`
      }

    case "star":
      return {
        heading: "Save this for later",
        description: `Sign ${upOrIn} to Paint Pad to save the recipes you use. Quickly look them up whenever you need them, on mobile or desktop.`
      }

    default:
      return { heading: `Sign ${upOrIn} to Paint Pad`, description: null }
  }
}

const HeaderContent = ({ mode, isSignUp }: HeaderContentProps) => {
  const { heading, description } = headerContent(mode, isSignUp)

  return (
    <>
      <Dialog.Title className="modal__heading">{heading}</Dialog.Title>
      {description ? (
        <Dialog.Description className="modal__description">
          {description}
        </Dialog.Description>
      ) : null}
    </>
  )
}

export default HeaderContent
