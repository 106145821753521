import * as React from "react"
import { useState } from "react"
import { ApolloProvider, useQuery } from "@apollo/client"
import { reverse, sortBy } from "lodash"

import apolloClient from "../../lib/apollo_client"

import CollectionsContext from "./contexts/collections_context"

import LogEntryList from "./components/log_entry_list"
import CollectionList from "./components/collection_list"
import { Placeholder, Heading1, PrimaryButton } from "components/brush"

import GetViewer from "./queries/get_viewer.graphql"

const HobbyLog = () => {
  const [isAddingLogEntry, setIsAddingLogEntry] = useState(false)

  const {
    loading: loadingViewer,
    error: viewerError,
    data: { viewer } = {}
  } = useQuery(GetViewer)

  const handleAddLogEntry = () => setIsAddingLogEntry(true)
  const handleCancelAddLogEntry = () => setIsAddingLogEntry(false)

  const collections = sortBy(viewer?.collections?.nodes, ({ name }) => name.toLowerCase())
  const logEntries = reverse(sortBy(viewer?.logEntries?.nodes, "completedOn"))

  return (
    <CollectionsContext.Provider value={collections}>
      <div className="pb-8">
        <header className="xs:flex xs:items-center mb-8">
          <Heading1 className="m-0 xs:mr-6">Your hobby log</Heading1>

          <div>
            <PrimaryButton
              onClick={handleAddLogEntry}
              disabled={isAddingLogEntry || loadingViewer || (viewerError ? true : false)}
              size="small"
              className="m-0 mt-2"
            >
              Add log entry
            </PrimaryButton>
          </div>
        </header>

        {loadingViewer ? (
          <Placeholder>
            <p className="text-2xl my-10">Loading your hobby log...</p>
          </Placeholder>
        ) : viewerError ? (
          <Placeholder>
            <p className="text-2xl my-10 font-semibold">
              There was a problem loading your hobby log.
            </p>
            <p className="text-2xl my-10">
              Please try reloading this page or{" "}
              <a href="mailto:support@paintpad.app">email support</a> if that
              doesn&rsquo;t help.
            </p>
          </Placeholder>
        ) : (
          <div className="lg:flex">
            <div className="flex-1">
              <LogEntryList
                viewerId={apolloClient.cache.identify(viewer)!}
                logEntries={logEntries}
                isAdding={isAddingLogEntry}
                handleCancel={handleCancelAddLogEntry}
              />
            </div>

            <div className="grow-0 shrink-0 lg:basis-[30rem] lg:ml-10 xl:ml-20">
              <CollectionList
                viewerId={apolloClient.cache.identify(viewer)!}
                collections={collections}
                logEntries={logEntries}
              />
            </div>
          </div>
        )}
      </div>
    </CollectionsContext.Provider>
  )
}

const HobbyLogWithApolloClient = () => (
  <ApolloProvider client={apolloClient}>
    <HobbyLog />
  </ApolloProvider>
)

export default HobbyLogWithApolloClient
