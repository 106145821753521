import * as React from "react"

import showAuthenticationModal from "lib/show_authentication_modal"
import useOwnedAndNeededPaints from "hooks/use_owned_and_needed_paints"

import AddCircleOutlineIcon from "../../../views/icons/add_circle_outline.svg"
import CheckCircleOutlineIcon from "../../../views/icons/check_circle_outline.svg"
import ListAddIcon from "../../../views/icons/list_add.svg"
import ListAddedIcon from "../../../views/icons/list_added.svg"

import "./styles.scss"

export type PaintInventoryActionProps = {
  isAuthenticated: boolean
  isCompact: boolean
  internalId: string
  quantities: { [index: string]: number }
  neededPaints: [string]
  ownershipsUrl: string
  shoppingListItemsUrl: string
}

const PaintInventoryActions = ({
  isAuthenticated,
  isCompact,
  internalId,
  quantities,
  neededPaints,
  ownershipsUrl,
  shoppingListItemsUrl
}: PaintInventoryActionProps) => {
  const ownedAndNeededPaints = useOwnedAndNeededPaints({
    quantities,
    neededPaints,
    ownershipsUrl,
    shoppingListItemsUrl
  })

  const { quantity, needed, setQuantity, setNeeded } = ownedAndNeededPaints.forPaint({
    id: internalId
  })

  const addToInventory = () =>
    isAuthenticated
      ? setQuantity(1)
      : showAuthenticationModal("inventory", { paint_id: internalId, quantity: 1 })

  const removeFromInventory = () => setQuantity(0)

  const addToShoppingList = () =>
    isAuthenticated
      ? setNeeded(true)
      : showAuthenticationModal("shopping_list", { paint_id: internalId })

  const removeFromShoppingList = () => setNeeded(false)

  return (
    <div className="paint-inventory-actions">
      <div className="paint-inventory-actions__action">
        {quantity > 0 ? (
          <>
            <div className="paint-inventory-actions__status">
              <button
                className="paint-inventory-actions__button paint-inventory-actions__button--active"
                type="button"
                title="Remove from your inventory"
                onClick={removeFromInventory}
              >
                <CheckCircleOutlineIcon />
              </button>
              <div className="quantity-spinner">
                <button
                  type="button"
                  className="quantity-spinner__button"
                  title="Decrease quantity owned"
                  onClick={() => setQuantity(quantity - 1)}
                >
                  -
                </button>
                <span className="quantity-spinner__value">{quantity}</span>
                <button
                  type="button"
                  className="quantity-spinner__button"
                  title="Increase quantity owned"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </button>
              </div>
              {isCompact ? "owned" : "in your inventory"}
            </div>
            {!isCompact ? (
              <button
                className="paint-inventory-actions__button"
                type="button"
                title="Remove from your inventory"
                onClick={removeFromInventory}
              >
                Remove
              </button>
            ) : null}
          </>
        ) : (
          <button
            className="paint-inventory-actions__button"
            type="button"
            title="Add to your inventory"
            onClick={addToInventory}
          >
            <AddCircleOutlineIcon />
            {isCompact ? "Inventory" : "Add to your inventory"}
          </button>
        )}
      </div>
      <div className="paint-inventory-actions__action">
        {needed ? (
          <>
            <button
              className="paint-inventory-actions__button paint-inventory-actions__button--active"
              type="button"
              title="Remove from your shopping list"
              onClick={removeFromShoppingList}
            >
              <ListAddedIcon />
              {isCompact ? "Needed" : "On your shopping list"}
            </button>

            {!isCompact ? (
              <button
                className="paint-inventory-actions__button"
                type="button"
                title="Remove from your shopping list"
                onClick={removeFromShoppingList}
              >
                Remove
              </button>
            ) : null}
          </>
        ) : (
          <button
            className="paint-inventory-actions__button"
            type="button"
            title="Add to your shopping list"
            onClick={addToShoppingList}
          >
            <ListAddIcon />
            {isCompact ? "Shopping list" : "Add to your shopping list"}
          </button>
        )}
      </div>
    </div>
  )
}

export default PaintInventoryActions
