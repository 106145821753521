import React, { useReducer } from "react"

import Item from "./components/item"

import "./styles/index.scss"

const reducer = (state, id) => state.filter(item => item.id != id)

const ShoppingList = props => {
  const [items, dispatch] = useReducer(reducer, props.items)

  return (
    <ul className="shopping-list">
      {items.map(item => (
        <Item key={item.id} handleRemove={() => dispatch(item.id)} {...item} />
      ))}
    </ul>
  )
}

export default ShoppingList
