import React from "react"
import ReactComponentController from "lib/react_component_controller"

import SupporterHoverCard from "components/supporter_hover_card"

export default class extends ReactComponentController {
  get component() {
    return <SupporterHoverCard target={this.element.innerHTML} {...this.props} />
  }
}
