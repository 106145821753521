import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  get output() {
    return this.inputTarget.value
  }

  update() {
    this.outputTarget.textContent = this.output
  }
}
