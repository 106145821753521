import React from "react"
import ReactComponentController from "lib/react_component_controller"

import PaintList from "components/paint_list"

export default class extends ReactComponentController {
  get component() {
    return <PaintList {...this.props} />
  }
}
