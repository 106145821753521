import * as React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import classNames from "classnames"
import { useEffect, useState } from "react"

import HeaderContent from "./components/header_content"
import SignUpForm from "./components/sign_up_form"
import SignInForm from "./components/sign_in_form"
import { AuthenticationModalProps, UserAction } from "./types"
import { AnalyticsEvent, track } from "../../lib/analytics"

import CloseIcon from "../../../views/icons/close.svg"
import "./styles.scss"

interface UserActionParams {
  paint_id?: string
  paint_ids?: [string]
  quantity?: number
}

const parseUserActionParams = ({
  mode,
  params
}: {
  mode: string
  params: UserActionParams
}) => {
  switch (mode) {
    case "inventory":
      return {
        name: "update_paint_ownership",
        params
      }

    case "shopping_list":
      return {
        name: "add_all_paints_to_shopping_list",
        params: {
          paint_ids: params.paint_id
        }
      }

    case "star":
      return {
        name: "add_or_remove_star",
        params
      }

    default:
      return undefined
  }
}

const analyticsProperties = (mode: string | undefined) => ({
  mode
})

const handleOnOpenAutoFocus = (event: Event) => {
  event.preventDefault()
}

const handleOnEscapeKeyDown = (event: Event) => {
  const element = event.target as HTMLElement

  // Don't close the modal if an <input /> has focus
  if (element.tagName === "INPUT") {
    event.preventDefault()
  }
}

const AuthenticationModal = ({
  csrfParam,
  csrfToken,
  recaptchaSiteKey,
  redirectToPath,
  signInPath,
  signUpPath
}: AuthenticationModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mode, setMode] = useState<string>()
  const [userAction, setUserAction] = useState<UserAction>()
  const [showSignUpForm, setShowSignUpForm] = useState(true)

  const handleShow = (event: Event) => {
    const detail = (event as CustomEvent).detail

    setIsOpen(true)
    setMode(detail.mode)
    setUserAction(parseUserActionParams(detail))
    track(AnalyticsEvent.OPEN_AUTHENTICATION_MODAL, analyticsProperties(detail.mode))
  }

  const handleShowSignUpForm = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowSignUpForm(true)

    track(
      AnalyticsEvent.SWITCH_TO_SIGN_UP_WITHIN_AUTHENTICATION_MODAL,
      analyticsProperties(mode)
    )
  }

  const handleShowSignInForm = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowSignUpForm(false)

    track(
      AnalyticsEvent.SWITCH_TO_SIGN_IN_WITHIN_AUTHENTICATION_MODAL,
      analyticsProperties(mode)
    )
  }

  const handleOnOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen)

    track(AnalyticsEvent.CLOSE_AUTHENTICATION_MODAL, analyticsProperties(mode))
  }

  useEffect(() => {
    window.addEventListener("authentication-modal.show", handleShow)

    return () => {
      window.removeEventListener("authentication-modal.show", handleShow)
    }
  }, [])

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="modal-overlay" />
        <Dialog.Content
          className="modal-container"
          onOpenAutoFocus={handleOnOpenAutoFocus}
          onEscapeKeyDown={handleOnEscapeKeyDown}
        >
          <div className="modal">
            <div className="modal__header">
              <HeaderContent mode={mode} isSignUp={showSignUpForm} />

              <Dialog.Close asChild>
                <button className="modal__close" aria-label="Close">
                  <CloseIcon />
                </button>
              </Dialog.Close>
            </div>

            <ul className="modal__tabs">
              <li>
                <a
                  href="#"
                  className={classNames("modal__tab", {
                    "modal__tab--active": showSignUpForm
                  })}
                  onClick={handleShowSignUpForm}
                >
                  Sign up
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={classNames("modal__tab", {
                    "modal__tab--active": !showSignUpForm
                  })}
                  onClick={handleShowSignInForm}
                >
                  Sign in
                </a>
              </li>
            </ul>

            <div className="modal__content">
              {showSignUpForm ? (
                <>
                  <SignUpForm
                    action={signUpPath}
                    csrfParam={csrfParam}
                    csrfToken={csrfToken}
                    recaptchaSiteKey={recaptchaSiteKey}
                    redirectToPath={redirectToPath}
                    userAction={userAction}
                  />
                  <p className="authentication-form__additional-actions">
                    Already have an account?{" "}
                    <a href="#" onClick={handleShowSignInForm}>
                      Sign in
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <SignInForm
                    action={signInPath}
                    csrfParam={csrfParam}
                    csrfToken={csrfToken}
                    redirectToPath={redirectToPath}
                    userAction={userAction}
                  />
                  <p className="authentication-form__additional-actions">
                    <span>
                      <a href="/passwords/new">Forgot password?</a>
                    </span>
                    Don’t have an account yet?{" "}
                    <a href="#" onClick={handleShowSignUpForm}>
                      Sign up
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AuthenticationModal
