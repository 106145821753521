import { Controller } from "stimulus"
import crel from "crel"

export default class extends Controller {
  static targets = ["original"]

  get originalContent() {
    return this.originalTarget.innerHTML
  }

  connect() {
    const fixedContent = crel("div", { class: "top-bar__fixed width-before-scroll-bar" })
    fixedContent.innerHTML = this.originalContent
    this.element.appendChild(fixedContent)

    this.element.classList.add("top-bar--fixed")
    this.originalTarget
      .querySelectorAll("a, button")
      .forEach(element => (element.tabIndex = -1))
  }
}
