import * as React from "react"
import { ReactElement } from "react"
import ReactComponentController from "lib/react_component_controller"

import AuthenticationModal from "components/authentication_modal"
import { AuthenticationModalProps } from "components/authentication_modal/types"

export default class extends ReactComponentController {
  get component(): ReactElement {
    const props = this.props as AuthenticationModalProps

    return <AuthenticationModal {...props} />
  }
}
