import * as React from "react"
import ReactDOM from "react-dom"
import { Controller } from "stimulus"

import PaintInventoryActions, {
  PaintInventoryActionProps
} from "components/paint_inventory_actions"

export default class extends Controller<HTMLDivElement> {
  static values = {
    props: Object
  }

  declare propsValue: PaintInventoryActionProps

  connect() {
    ReactDOM.render(<PaintInventoryActions {...this.propsValue} />, this.element)

    this.element.style.display = "block"
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element)
  }
}
