import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"

import CloseIcon from "../../../views/icons/close.svg"
import FilterIcon from "../../../views/icons/filter_list.svg"

import "./styles.scss"

const Label = ({ name, children }) => (
  <label htmlFor={name} className="filter-bar__label">
    {children}
  </label>
)

export const FilterBar = ({ isFiltered, onClear, toggleLabel, children: filters }) => {
  const [showFilters, setShowFilters] = useState(false)
  const isLargeBreakpoint = useMediaQuery({ query: "(min-width: 1101px)" })

  const toggleFilters = event => {
    event.preventDefault()
    setShowFilters(!showFilters)
  }

  return (
    <div className="filter-bar">
      <div className="safe-area">
        <div className="filter-bar__container">
          {!isLargeBreakpoint && (
            <a href="#" onClick={toggleFilters} className="filter-bar__toggle">
              <span className="filter-bar__toggle__icon">
                {showFilters ? <CloseIcon /> : <FilterIcon />}
              </span>
              <span className="filter-bar__toggle__label">{toggleLabel}</span>
            </a>
          )}

          {(showFilters || isLargeBreakpoint) && (
            <>
              <ul className="filter-bar__filters">
                {React.Children.toArray(filters).map((filter, i) => (
                  <li key={i} className="filter-bar__filter-container">
                    {filter}
                  </li>
                ))}
              </ul>

              <div className="filter-bar__clear">
                {isFiltered ? (
                  <a href="#" onClick={onClear}>
                    Clear filters
                  </a>
                ) : (
                  "Clear filters"
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export const SelectFilter = ({ name, label, value, onChange, children: options }) => (
  <div className="filter-bar__filter">
    <Label name={name}>{label}</Label>
    <select
      id={name}
      value={value}
      onChange={onChange}
      className="form__select filter-bar__select-filter"
    >
      <option value="">Select...</option>
      {options}
    </select>
  </div>
)

export const TextFilter = ({ name, label, value, onChange }) => (
  <div className="filter-bar__filter">
    <Label name={name}>{label}</Label>
    <input
      type="text"
      id="paint-name-filter"
      value={value}
      onChange={onChange}
      className="form__input filter-bar__text-filter"
      placeholder="Type..."
    />
  </div>
)
