import { Controller } from "stimulus"

import performDirectImageUpload from "lib/perform_direct_image_upload"
import { patch } from "lib/request"

export default class extends Controller {
  static targets = ["button", "input", "preview", "deleteLink"]

  connect() {
    this.lock = this.lock.bind(this)
    this.update = this.update.bind(this)
  }

  get directUploadUrl() {
    return this.data.get("directUploadUrl")
  }

  get currentUserAvatar() {
    return document.querySelector("[data-current-user-avatar]")
  }

  beginUpload() {
    performDirectImageUpload({
      fileInput: this.inputTarget,
      url: this.directUploadUrl,
      beforeUpload: this.lock,
      afterUpload: this.update
    })
  }

  update({ signed_id }) {
    patch(this.data.get("url"), { signed_id })
      .then(response => response.json())
      .then(({ preview, header }) => {
        this.previewTarget.innerHTML = preview
        this.currentUserAvatar.innerHTML = header

        this.unlock()
      })
      .catch(() => {
        // TODO: proper error handling?
        alert(
          "There was a problem uploading your avatar. Reload this page and try again."
        )
      })
  }

  lock() {
    this.deleteLinkTarget.disabled = true
    this.buttonTarget.firstChild.textContent = "Uploading picture..."
    this.buttonTarget.classList.add("button--disabled")
    this.inputTarget.disabled = true
  }

  unlock() {
    this.deleteLinkTarget.disabled = false
    this.buttonTarget.firstChild.textContent = "Upload new picture"
    this.buttonTarget.classList.remove("button--disabled")
    this.inputTarget.disabled = false
  }
}
