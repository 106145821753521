import * as React from "react"

import UserActionHiddenFields from "./user_action_hidden_fields"
import { SignUpFormProps } from "../types"

const SignUpForm = ({
  action,
  csrfParam,
  csrfToken,
  redirectToPath,
  recaptchaSiteKey,
  userAction
}: SignUpFormProps) => {
  return (
    <form action={action} method="post">
      <input type="hidden" name={csrfParam} value={csrfToken} />
      <input type="hidden" name="redirect_to_path" value={redirectToPath} />
      {userAction ? <UserActionHiddenFields {...userAction} /> : null}

      <div className="authentication-form__field ">
        <label
          className="form__label authentication-form__label"
          htmlFor="sign_up_form_username"
        >
          Username
        </label>
        <input
          className="form__input authentication-form__input"
          type="text"
          name="sign_up_form[username]"
          id="sign_up_form_username"
        />

        <p className="form__hint">
          Pick something snazzy. Letters, numbers, hyphens, underscores, and full stops.
        </p>
      </div>

      <div className="authentication-form__field ">
        <label
          className="form__label authentication-form__label"
          htmlFor="sign_up_form_email"
        >
          Email
        </label>
        <input
          className="form__input authentication-form__input"
          type="text"
          name="sign_up_form[email]"
          id="sign_up_form_email"
        />

        <p className="form__hint">We won’t send you any marketing email. Ever.</p>
      </div>

      <div className="authentication-form__field ">
        <label
          className="form__label authentication-form__label"
          htmlFor="sign_up_form_password"
        >
          Password
        </label>
        <input
          className="form__input authentication-form__input"
          type="password"
          name="sign_up_form[password]"
          id="sign_up_form_password"
        />

        <p className="form__hint">
          The longer the better. Don’t use a password that you use elsewhere.
        </p>
      </div>

      <div className="authentication-form__field ">
        <label className="form__checkbox">
          <div className="form__checkbox__input">
            <input
              name="sign_up_form[agree_code_of_conduct]"
              type="hidden"
              value="0"
              autoComplete="off"
            />
            <input
              type="checkbox"
              value="1"
              name="sign_up_form[agree_code_of_conduct]"
              id="sign_up_form_agree_code_of_conduct"
            />
          </div>
          <div className="form__checkbox__content">
            <div className="form__checkbox__label">
              Paint Pad is an inclusive community, make sure you have read and agree to
              follow our{" "}
              <a target="_blank" href="/code-of-conduct">
                code of conduct
              </a>
            </div>
          </div>
        </label>
      </div>

      <div className="authentication-form__field ">
        <label className="form__checkbox">
          <div className="form__checkbox__input">
            <input
              name="sign_up_form[agree_terms_of_use]"
              type="hidden"
              value="0"
              autoComplete="off"
            />
            <input
              type="checkbox"
              value="1"
              name="sign_up_form[agree_terms_of_use]"
              id="sign_up_form_agree_terms_of_use"
            />
          </div>
          <div className="form__checkbox__content">
            <div className="form__checkbox__label">
              Creating an account means you’re okay with our{" "}
              <a target="_blank" href="/terms-of-use">
                terms of use
              </a>{" "}
              and{" "}
              <a target="_blank" href="/privacy-policy">
                privacy policy
              </a>
            </div>
          </div>
        </label>
      </div>

      <div className="authentication-form__field ">
        <div className="form__captcha">
          <div
            data-controller="recaptcha"
            data-recaptcha-site-key-value={recaptchaSiteKey}
          />
        </div>
      </div>

      <div className="authentication-form__action">
        <input
          type="submit"
          name="commit"
          value="Create account"
          className="button authentication-form__button"
          data-disable-with="Create Sign up form"
        />
      </div>
    </form>
  )
}

export default SignUpForm
