import * as React from "react"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { formatISO } from "date-fns"
import { useContext } from "react"

import * as Field from "./form/field"
import DateField from "./form/date_field"
import SelectField from "./form/select_field"
import TextField from "./form/text_field"
import NumberField from "./form/number_field"
import TextArea from "./form/text_area"

import CollectionsContext from "../contexts/collections_context"
import { Collection, LogEntryFormValues } from "../types"
import { Heading2, PrimaryButton, SecondaryButton } from "components/brush"

interface LogEntryFormProps {
  values?: LogEntryFormValues
  onCancel: () => void
  onSubmit: (values: LogEntryFormValues) => void
}

const LogEntrySchema = Yup.object({
  description: Yup.string().required("Enter a description for this hobby log entry"),
  completedOn: Yup.date()
    .required("Enter a date of completion for this hobby log entry")
    .test(
      "not-in-future",
      "Only those corrupted by Chaos can finish painting models in the future!",
      (value, _) => {
        if (value) {
          return value <= new Date()
        } else {
          return false
        }
      }
    ),
  collection: Yup.object().nullable(),
  notes: Yup.string().nullable(),
  models: Yup.number().nullable(),
  points: Yup.number().nullable()
})

const LogEntryForm = ({ values, onCancel, onSubmit }: LogEntryFormProps): JSX.Element => {
  const collections = useContext(CollectionsContext)

  const defaultValues: LogEntryFormValues = {
    description: "",
    completedOn: formatISO(new Date(), { representation: "date" }),
    collection: null,
    notes: null,
    models: null,
    points: null
  }

  const initialValues: LogEntryFormValues = values
    ? { ...defaultValues, ...values }
    : defaultValues

  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault()

    onCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={LogEntrySchema}
    >
      {formik => (
        <Form className="py-3 px-4 pb-4 sm:py-6 sm:px-8 sm:pb-8 mb-12 -mx-2 xs:mx-0 border-black border-2 border-solid shadow-square">
          <Heading2 className="m-0 mb-6">{values ? "Edit" : "Add"} log entry</Heading2>

          <Field.Container name="description">
            <Field.Label>What did you finish?</Field.Label>
            <TextField autoFocus />
            <Field.Error />
          </Field.Container>

          <Field.Container name="completedOn">
            <Field.Label>When did you finish it?</Field.Label>
            <DateField required />
            <Field.Error />
          </Field.Container>

          <Field.Container name="collection">
            <Field.Label>Collection</Field.Label>
            <SelectField
              isClearable={true}
              options={collections}
              getOptionLabel={({ name }: Collection) => name}
              getOptionValue={({ id }: Collection) => id.toString()}
            />
            <Field.Error />
          </Field.Container>

          <Field.Container name="notes">
            <Field.Label>Notes</Field.Label>
            <TextArea />
            <Field.Error />
          </Field.Container>

          <div className="flex">
            <Field.Container name="models" className="mb-0 mr-6">
              <Field.Label>Models</Field.Label>
              <NumberField min="0" step="1" />
              <Field.Error />
            </Field.Container>

            <Field.Container name="points" className="mb-0">
              <Field.Label>Points</Field.Label>
              <NumberField min="0" step="1" />
              <Field.Error />
            </Field.Container>
          </div>

          <div className="flex mt-10 items-center">
            <div className="mr-4">
              <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Saving..." : "Save"}
              </PrimaryButton>
            </div>
            <div>
              <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default LogEntryForm
