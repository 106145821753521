import React from "react"
import ReactComponentController from "lib/react_component_controller"

import PaintCollection from "components/paint_collection"

export default class extends ReactComponentController {
  get component() {
    return <PaintCollection {...this.props} />
  }
}
