import { Controller } from "stimulus"

export default class extends Controller {
  toggle() {
    this.element.classList.toggle("menu-is-open")
  }

  disconnect() {
    this.element.classList.remove("menu-is-open")
  }
}
