import React, { useReducer } from "react"
import { useQuery, useMutation } from "react-query"
import { uniq } from "lodash"

import Fields from "./components/fields"
import alternativePaintsReducer from "./lib/alternative_paints_reducer"
import { fetchAlternativePaints, persistAlternativePaints } from "./lib/requests"

import "./styles.scss"

const AlternativePaintSelector = ({
  recipeId,
  paints,
  paintRanges,
  paintQuantities,
  onSubmit,
  onCancel,
  saveAlternativePaints,
  selectedAlternativePaints: initialAlternativePaints
}) => {
  const paintIds = paints.map(({ id }) => id)

  const [selectedAlternativePaints, changeAlternativePaint] = useReducer(
    alternativePaintsReducer,
    initialAlternativePaints
  )
  const { data, status } = useQuery(["alternative-paints", paintIds], () =>
    fetchAlternativePaints(paintIds)
  )
  const { mutateAsync: persist, status: persistingStatus } = useMutation(
    persistAlternativePaints
  )

  const handleSubmit = () => {
    const paints = uniq(Object.values(selectedAlternativePaints)).map(
      paintId => data.alternativePaints[paintId]
    )
    const paintRanges = uniq(paints.map(paint => paint.paintRangeId)).map(
      paintRangeId => data.alternativePaintRanges[paintRangeId]
    )

    const args = {
      selectedAlternativePaints,
      paints,
      paintRanges,
      ownedAndNeededAlternativePaints: data.ownedAndNeededAlternativePaints
    }

    if (saveAlternativePaints) {
      persist({
        recipeId,
        alternatives: selectedAlternativePaints
      }).then(() => onSubmit(args))
    } else {
      onSubmit(args)
    }
  }

  return (
    <aside
      aria-label="Alternative paints selector"
      className="alternative-paint-selector"
    >
      <div className="alternative-paint-selector__container">
        <h1 className="alternative-paint-selector__heading">
          Choose alternative paints for this recipe
        </h1>
        <p className="alternative-paint-selector__description">
          Alternative paint options are based on data published by paint manufacturers.{" "}
          <a href="mailto:support@paintpad.app">Suggest an additional data source</a>
        </p>
      </div>

      {status === "success" ? (
        <Fields
          data={data}
          paints={paints}
          paintRanges={paintRanges}
          paintQuantities={paintQuantities}
          selectedAlternativePaints={selectedAlternativePaints}
          changeAlternativePaint={changeAlternativePaint}
          isPersisting={persistingStatus === "loading"}
        />
      ) : (
        <div className="alternative-paint-selector__container">
          <p
            className={`alternative-paint-selector__status alternative-paint-selector__status--${status}`}
          >
            {status === "loading"
              ? "Loading alternative paints for this recipe..."
              : "There was a problem loading alternative paints for this recipe"}
          </p>
        </div>
      )}

      <div className="alternative-paint-selector__container alternative-paint-selector__container--actions">
        {saveAlternativePaints ? (
          <button
            onClick={handleSubmit}
            className="button button--flat"
            disabled={persistingStatus === "loading" || status !== "success"}
          >
            {persistingStatus === "loading" ? "Saving..." : "Save"}
          </button>
        ) : (
          <>
            <button
              onClick={handleSubmit}
              className="button button--flat"
              disabled={status !== "success"}
            >
              Choose
            </button>
            <a href="/sign-in" className="alternative-paint-selector__cta">
              Sign in to save your selections
            </a>
          </>
        )}

        <button
          onClick={onCancel}
          className="button button--flat button--secondary alternative-paint-selector__cancel-button"
        >
          Cancel
        </button>
      </div>
    </aside>
  )
}

export default AlternativePaintSelector
