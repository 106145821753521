import React, { useEffect, useState } from "react"
import ReactComponentController from "lib/react_component_controller"

import PaintRangeCount from "components/paint_range_count"

const EventedPaintRangeCount = ({ paintRangeId, total, isAuthenticated, ...props }) => {
  const [owned, setOwned] = useState(props.owned)

  const handleOwnershipChange = event => {
    setOwned(event.detail.ownerships[paintRangeId])
  }

  useEffect(() => {
    window.addEventListener("paint-ownerships-changed", handleOwnershipChange)

    return () => {
      window.removeEventListener("paint-ownerships-changed", handleOwnershipChange)
    }
  }, [])

  return <PaintRangeCount owned={owned} total={total} isAuthenticated={isAuthenticated} />
}

export default class extends ReactComponentController {
  get component() {
    return <EventedPaintRangeCount {...this.props} />
  }
}
