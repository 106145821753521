import * as React from "react"
import Select, {
  GroupBase,
  OnChangeValue,
  Props,
  StylesConfig,
  Theme
} from "react-select"
import { useField } from "formik"

import { useFieldContainer } from "./field"

const theme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    // These colors come from Tailwind: https://tailwindcss.com/docs/customizing-colors
    primary: "#f97316", // Orange 500,
    primary25: "#ffedd5", // Orange 100,
    primary50: "#fed7aa", // Orange 200,
    primary75: "#fdba74" // Orange 300
  },
  borderRadius: 4
})

const SelectField = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  const { id, name, errorId } = useFieldContainer()
  const [field, meta, helpers] = useField(name)

  const onChange = (newValue: OnChangeValue<Option, IsMulti>) =>
    helpers.setValue(newValue)

  const styles: StylesConfig<Option, IsMulti, Group> = {
    control: (provided, state) => ({
      ...provided,
      borderWidth: "2px",
      boxShadow: "none",
      borderColor: state.isFocused
        ? state.theme.colors.primary
        : state.theme.colors.neutral20,
      outline: state.isFocused ? "2px solid black" : provided.outline,
      outlineWidth: "4px",
      outlineColor: state.theme.colors.primary25,
      transition: "none",
      "&:hover": {
        borderColor: state.isFocused
          ? state.theme.colors.primary
          : state.theme.colors.neutral30
      }
    }),
    input: (provided, _) => ({
      ...provided,
      fontSize: "20px"
    }),
    placeholder: (provided, _) => ({
      ...provided,
      fontSize: "20px"
    }),
    singleValue: (provided, _) => ({
      ...provided,
      fontSize: "20px"
    }),
    indicatorSeparator: (provided, _) => ({
      ...provided,
      margin: 0
    })
  }

  return (
    <Select
      inputId={id}
      aria-describedby={meta.error ? errorId : null}
      name={field.name}
      value={field.value}
      onChange={onChange}
      styles={styles}
      theme={theme}
      {...props}
    />
  )
}

export default SelectField
