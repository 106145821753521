export default (state, { originalPaintId, alternativePaint }) => {
  if (alternativePaint) {
    return {
      ...state,
      [originalPaintId]: alternativePaint.id
    }
  } else {
    // eslint-disable-next-line no-unused-vars
    const { [originalPaintId]: _deleted, ...rest } = state
    return rest
  }
}
