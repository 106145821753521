import * as React from "react"

import UserActionHiddenFields from "./user_action_hidden_fields"
import { SignInFormProps } from "../types"

const SignInForm = ({
  action,
  csrfParam,
  csrfToken,
  redirectToPath,
  userAction
}: SignInFormProps) => {
  return (
    <form action={action} method="post">
      <input type="hidden" name={csrfParam} value={csrfToken} />
      <input type="hidden" name="redirect_to_path" value={redirectToPath} />
      {userAction ? <UserActionHiddenFields {...userAction} /> : null}

      <div className="authentication-form__field">
        <label className="form__label" htmlFor="session_email">
          Email or username
        </label>
        <input
          className="form__input authentication-form__input"
          type="text"
          name="session[email]"
          id="session_email"
        ></input>
      </div>

      <div className="authentication-form__field">
        <label className="form__label" htmlFor="session_password">
          Password
        </label>
        <input
          className="form__input authentication-form__input"
          type="password"
          name="session[password]"
          id="session_password"
        />
      </div>

      <div className="authentication-form__action">
        <input
          type="submit"
          name="commit"
          value="Sign in"
          className="button authentication-form__button"
          data-disable-with="Sign in"
        />
      </div>
    </form>
  )
}

export default SignInForm
