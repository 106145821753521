import React from "react"
import Select from "react-select"
import { keyBy, sortBy } from "lodash"

import PaintSummary from "../../paint_summary"
import PaintOption from "./paint_option"
import PaintSingleValue from "./paint_single_value"
import PaintsWithoutAlternativesList from "./paints_without_alternatives_list"

const selectedAlternative = (
  id,
  selectedAlternativePaints,
  { alternativePaints, ownedAndNeededAlternativePaints: { quantities } }
) => {
  const selectedAlternative = alternativePaints[selectedAlternativePaints[id]]

  if (selectedAlternative) {
    return {
      ...selectedAlternative,
      isOwned: quantities[selectedAlternative.id] > 0
    }
  } else {
    return null
  }
}

const alternativeOptions = (
  id,
  {
    availableAlternatives,
    alternativePaints,
    alternativePaintRanges,
    ownedAndNeededAlternativePaints: { quantities }
  }
) =>
  availableAlternatives[id].map(paintId => {
    const paint = alternativePaints[paintId]

    return {
      ...paint,
      isOwned: quantities[paintId] > 0,
      paintRange: alternativePaintRanges[paint.paintRangeId]
    }
  })

const Fields = ({
  data,
  paints,
  paintRanges,
  paintQuantities,
  selectedAlternativePaints,
  changeAlternativePaint,
  isPersisting
}) => {
  const paintRangesIndexedById = keyBy(paintRanges, "id")

  const setSelectedPaint = originalPaintId => alternativePaint =>
    changeAlternativePaint({ originalPaintId, alternativePaint })

  const paintsWithAlternatives = paints.filter(
    ({ id }) => data.availableAlternatives[id].length > 0
  )

  const paintsWithoutAlternatives = sortBy(
    paints.filter(({ id }) => data.availableAlternatives[id].length === 0),
    "name"
  )

  return (
    <div className="alternative-paint-selector__container">
      <div className="alternative-paint-selector__fields">
        {paintsWithAlternatives.map(paint => (
          <div className="form__field alternative-paint-selector__field" key={paint.id}>
            <span className="alternative-paint-selector__swap">Swap</span>
            <label
              className="alternative-paint-selector__label"
              htmlFor={`alternative-paint-${paint.id}`}
            >
              <PaintSummary
                paintRange={paintRangesIndexedById[paint.paintRangeId]}
                isOwned={paintQuantities[paint.id] > 0}
                {...paint}
              />
            </label>
            <span className="alternative-paint-selector__with">with</span>

            <div className="alternative-paint-selector__select">
              <Select
                placeholder="Select an alternative paint..."
                options={alternativeOptions(paint.id, data)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                value={selectedAlternative(paint.id, selectedAlternativePaints, data)}
                onChange={setSelectedPaint(paint.id)}
                isClearable={true}
                isDisabled={isPersisting}
                inputId={`alternative-paint-${paint.id}`}
                components={{
                  Option: PaintOption,
                  SingleValue: PaintSingleValue
                }}
              />
            </div>
          </div>
        ))}
      </div>

      {paintsWithoutAlternatives.length > 0 && (
        <PaintsWithoutAlternativesList paints={paintsWithoutAlternatives} />
      )}
    </div>
  )
}

export default Fields
