import * as React from "react"
import { useField } from "formik"

import { useFieldContainer } from "./field"
import { Input } from "components/brush"

const DateField = (props: React.ComponentPropsWithoutRef<"input">) => {
  const { id, name, errorId } = useFieldContainer()
  const [field, meta, _helpers] = useField(name)

  return (
    <Input
      type="date"
      id={id}
      aria-describedby={meta.error ? errorId : undefined}
      className="w-52"
      {...field}
      {...props}
    />
  )
}

export default DateField
